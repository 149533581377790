import classNames from 'classnames';
import './BackgroundDots.scss';

export const BACKGROUND_DOTS_TYPE = {
  LINEAR: 'linear',
  RADIAL: 'radial',
};

export const BACKGROUND_DOTS_DIRECTION = {
  TOP_LEFT: 'top-left',
  BOTTOM_RIGHT: 'bottom-right',
  BOTTOM_LEFT: 'bottom-left'
};

const BackgroundDots = (props) => {
  const {
    outerStyle,
    style,
    type,
    direction
  } = props;

  return (
    <div
      style={outerStyle}
      className={
        classNames(
          'background-dots',
          `background-dots--type-${type}`,
          `background-dots--direction-${direction}`
        )
      }
    >
      <div
        className={'background-dots__position'}
        style={style}
      >
        <div className={'background-dots__dots'} />
        <div className={'background-dots__overlay'} />
      </div>
    </div>
  );
};

BackgroundDots.defaultProps = {
  type: BACKGROUND_DOTS_TYPE.LINEAR,
  direction: BACKGROUND_DOTS_DIRECTION.TOP_LEFT
};

export default BackgroundDots;

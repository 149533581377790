import { useMemo } from 'react';
import { resolveChildren } from '../../lib/Utils';
import { useMeasure } from '_Hooks';
import classNames from 'classnames';
import './Footer.scss';

const Left = ({ className = '', ...props }) => <div className={`system-layout-footer__left ${className}`} {...props} />;
const Center = ({ className = '', bind, ...props }) => <div className={`system-layout-footer__center ${className}`} {...bind} {...props} />;
const Right = ({ className = '', ...props }) => <div className={`system-layout-footer__right ${className}`} {...props} />;

const Footer = ({ style, children, className }) => {

  const [bind, { height }] = useMeasure();

  const InjectedFooter = useMemo(
    () => props => <Center bind={bind} {...props} />,
    []
  );

  return (
    <div
      className={
        classNames(
          'system-layout-footer',
          {
            [className]: className
          }
        )
      }
      style={{ ...style, minHeight: height }}
      data-cy={'system-layout-footer'}
    >
      {resolveChildren(
        children,
        {
          Left,
          Center: InjectedFooter,
          Right
        }
      )}
    </div>
  );
};

export default Footer;

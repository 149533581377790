import { cloneElement, createRef, useEffect, useRef, useState } from 'react';
import { useAPICallback } from '_Hooks';
import { isFunction, sleep } from '../../lib/Utils';

const ViewportListRenderer = (props) => {
  const {
    items,
    overscan,
    getKey,
    children
  } = props;

  const refs = useRef({});

  const [, update] = useState(() => ({}));

  const delayedResize = useAPICallback(
    async api => {
      await sleep(150);
      if (api.current) update({});
    },
    [update]
  );

  useEffect(
    () => {
      window.addEventListener('scroll', delayedResize);
      window.addEventListener('resize', delayedResize);
      document.addEventListener('resize', delayedResize);
      const body = document.querySelector('#body-wrapper > .container#wide-container');
      const ro = new ResizeObserver(delayedResize);
      ro.observe(body);
      update({});
      return () => {
        window.removeEventListener('scroll', delayedResize);
        window.removeEventListener('resize', delayedResize);
        document.removeEventListener('resize', delayedResize);
        ro.disconnect(body);
      };
    },
    [update, delayedResize]
  );

  if (!isFunction(children)) throw new Error('ViewportListRenderer requires a function of args [item, index, {}] as its children');
  return (
    <>
      {items.map(
        (item, index) => {
          const key = getKey(item);
          if (!refs.current[key]) refs.current[key] = createRef();

          let visible = false;
          if (refs.current[key].current) {
            const { top, height } = refs.current[key].current.getBoundingClientRect();
            visible = (top >= -overscan) && ((top + height) <= (window.innerHeight + overscan));
          }

          const node = children(
            item,
            index,
            {
              visible,
              ref: refs.current[key]
            }
          );
          return cloneElement(node, { key });
        }
      )}
    </>
  );
};

ViewportListRenderer.defaultProps = {
  items: [],
  overscan: 0,
  getKey: () => {},
  children: () => <></>
};

export default ViewportListRenderer;
